<template>
  <div style="width: 90% !important" class="mx-auto">
    <!-- <div v-if="currentWorkspace?.connected"> -->
    <v-row class="my-2">
      <v-col cols="6"></v-col>

      <!-- Date Filter -->
      <v-col cols="6">
        <div class="d-flex flex-row justify-end align-center">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value="dates"
            persistent
            width="290px"
          >
            <v-date-picker v-model="dates" range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="setCustomDate()"> OK </v-btn>
            </v-date-picker>
          </v-dialog>
          <v-combobox
            hide-details
            dense
            outlined
            placeholder="Select Date Filter"
            :items="dateValues"
            item-text="title"
            item-value="value"
            v-model="dateFilter"
            class="mr-4"
            @change="setDateValue()"
          />
          <!-- </v-col> -->

          <!-- Search filter -->
          <!-- <v-col cols="3"> -->
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
          <!-- </v-col> -->

          <!-- Columns hide/show -->
          <!-- <v-col cols="1"> -->
          <div class="text-center">
            <v-menu offset-y :close-on-content-click="false" v-model="menu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  min-width="41"
                  class="settings-btn justify-end pa-0"
                >
                  <SettingsButton />
                </v-btn>
              </template>
              <v-container class="settings">
                <v-sheet
                  class="f-inter fs-14 fw-600 mt-2 mb-3"
                  style="color: var(--v-lightPurple-base)"
                  >Default</v-sheet
                >
                <!-- Top checkbox content -->
                <v-row no-gutters>
                  <v-col
                    cols="6"
                    v-for="(item, index) in headerOptions"
                    :key="index"
                    v-show="item.showInDefault"
                  >
                    <v-checkbox
                      class="settings-checkbox mt-0 mb-3"
                      :label="item.text"
                      hide-details
                      v-model="columnsToAdd"
                      :value="item.value"
                      :ripple="false"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-divider class="border-opacity-25"></v-divider>
                <v-sheet
                  class="f-inter fs-14 fw-600 mt-3 mb-3"
                  style="color: var(--v-lightPurple-base)"
                  >More Columns</v-sheet
                >
                <!-- Bottom checkbox content -->
                <!-- v-for="(item, index) in (retainedValue ? optionalHeaders : moreColumnsRetainedValue)" -->
                <v-row no-gutters>
                  <v-col
                    cols="6"
                    v-for="(item, index) in headerOptions"
                    :key="index"
                    v-show="!item.showInDefault"
                  >
                    <v-checkbox
                      class="settings-checkbox mt-0 mb-3"
                      :label="item.text"
                      hide-details
                      v-model="columnsToAdd"
                      :ripple="false"
                      :value="item.value"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <div class="d-flex flex-row-reverse mt-3">
                  <v-btn class="custom-btn ml-3" @click="addColumns">
                    <span
                      class="fs-12 fw-500 f-inter text-none lightPurple--text"
                      >Save</span
                    >
                  </v-btn>
                  <v-btn
                    text-transform="none"
                    class="custom-btn ml-3"
                    @click="resetCheckbox"
                    ><span
                      class="fs-12 fw-500 f-inter text-none subHeading--text"
                      >Cancel</span
                    ></v-btn
                  >
                </div>
              </v-container>
            </v-menu>
          </div>
          <!-- <v-select
          v-model="selectedColumns"
          :items="headerOptions"
          item-text="text"
          item-value="value"
          label="Select Columns"
          multiple
          outlined
          hide-details
          dense
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 1">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ visibleHeaders.length - 1 }} others)</span
            >
          </template>
        </v-select> -->
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- hide-default-header -->
    <v-data-table
      :loading="loader"
      :headers="visibleHeaders"
      :items="flattenedJournals"
      :search="search"
      :group-by="['journalNumber']"
      fixed-header
      height="67.8vh"
      :itemsPerPage="pageItems"
      :hide-default-footer="true"
      class="text-no-wrap table-border"
    >
      <!-- <template slot="header">
        <thead>
          <tr>
            <th
              v-for="(header, headerKey) in visibleHeaders"
              :key="headerKey + 'A'"
              class="bottom-tr-td"
            >
              <span class="fs-14 fw-700 f-inter"> {{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:body="{ items }" class="mb-5 pb-5">
        <template v-for="item in items">
          <tr class="fs-12 fw-400 f-inter">
            <td
              v-for="(headers, index) in visibleHeaders"
              :key="index + 'A'"
              class="pl-1 bottom-tr-td pt-1 pb-1"
            >
              <v-col>
                <span
                  v-if="headers.value === 'journalDate'"
                  class="textPrimary--text"
                >
                  {{ item.journalDate | formatISODate }}
                </span>
                <span v-else-if="headers.value === 'createdDateUTC'">
                  {{ item.createdDateUTC | formatISODate }}
                </span>
                <span v-else-if="headers.value === 'journalNumberList'">
                  {{ item.journalNumber }}
                </span>
                <span v-else>
                  {{ item[headers.value] }}
                </span>
              </v-col>
            </td>
          </tr>
        </template>
      </template>
      <template v-slot:group.summary="{ items, group }">

        <td v-for="(item, index) in visibleHeaders" :key="index">
          <span v-if="item.value === 'credit'">
            {{ PRICESOBJ[user.currency ? user.currency[0] : ""]
            }}{{ sum(items, true) }}</span
          >
          <span v-else-if="index === debitIndex">
            {{ PRICESOBJ[user.currency ? user.currency[0] : ""]
            }}{{ sum(items, false) }}</span
          >
        </td>
      </template> -->

      <template v-slot:[`item.journalDate`]="{ item }">
        <span class="fs-13 fw-400 f-inter textPrimary--text">
          {{ item.journalDate | formatISODate }}
        </span>
      </template>

      <template v-slot:[`item.createdDateUTC`]="{ item }">
        <span class="fs-13 fw-400 f-inter textPrimary--text">
          {{ item.createdDateUTC | formatISODate }}
        </span>
      </template>

      <template v-slot:[`item.journalNumberList`]="{ item }">
        <span>{{ item.journalNumber }}</span>
      </template>

      <template v-slot:group.header="{ items, group }"> </template>
      <template v-slot:group.summary="{ items, group }">
        <!-- Calculate and display group summary -->

        <td v-for="(item, index) in visibleHeaders" :key="index">
          <span v-if="index === creditIndex">
            {{ currency | baseCurrency }}{{ sum(items, true) }}</span
          >
          <span v-else-if="index === debitIndex">
            {{ currency | baseCurrency }}{{ sum(items, false) }}</span
          >
        </td>
      </template>
    </v-data-table>
    <div class="mt-4">
      <pagination
        class="pa-0"
        :itemsPerPage="pageItems"
        :page="paginate.page"
        :pageCount="paginate.lastPage"
        :totalCount="paginate.total"
        @next-page-change="fetchJournalsFromDB($event)"
        @prev-page-change="fetchJournalsFromDB($event)"
        @page-number-changed="fetchJournalsFromDB($event)"
        @items-per-page-change="fetchJournalsFromDB($event)"
      ></pagination>
    </div>

    <!-- </div> -->
    <!-- <no-software-connected v-if="!currentWorkspace?.connected" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/Api";
import dateFilter from "../../../js/DateFilters.js";
import Pagination from "../../common/Pagination.vue";
import SettingsButton from "../../../assets/svg-icons/transaction-page-setings.vue";
// import NoSoftwareConnected from "../../common/connect-software/NoSoftwareConnected.vue";
export default {
  components: {
    Pagination,
    SettingsButton,
    // NoSoftwareConnected,
  },
  data() {
    return {
      columnsToAdd: [],
      menu: false,
      modal: false,
      currency: "",
      dateFilter: "",
      dateValues: [
        { title: "Today", value: "today" },
        { title: "Yesterday", value: "yesterday" },
        { title: "This Week", value: "thisWeek" },
        { title: "Previous Week", value: "previousWeek" },
        { title: "Last 2 weeks", value: "lastTwoWeeks" },
        { title: "This Month", value: "thisMonth" },
        { title: "Previous Month", value: "previousMonth" },
        { title: "This Quarter", value: "thisQuarter" },
        { title: "Previous Quarter", value: "previousQuarter" },
        { title: "This Year", value: "thisYear" },
        { title: "Previous Year", value: "previousYear" },
        { title: "Custom Date Range", value: "customDateRange" },
      ],
      menu: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dates: [],
      journals: [],
      loader: false,
      search: "",
      headers: [
        {
          text: "Journal Date",
          value: "journalDate",
          visible: true,
          showInDefault: true,
        },
        {
          text: "Journal No",
          value: "journalNumber",
          visible: true,
          showInDefault: true,
        },
        {
          text: "Journal No",
          value: "journalNumberList",
          visible: true,
          showInDefault: true,
        },
        {
          text: "Reference",
          value: "reference",
          visible: true,
          showInDefault: true,
        },
        {
          text: "Source Type",
          value: "sourceType",
          visible: true,
          showInDefault: true,
        },
        {
          text: "Account Name",
          value: "accountName",
          visible: true,
          showInDefault: true,
        },
        {
          text: "Description",
          value: "description",
          visible: true,
          showInDefault: true,
        },
        { text: "Debit", value: "debit", visible: true, showInDefault: true },
        { text: "Credit", value: "credit", visible: true, showInDefault: true },

        {
          text: "Created Date",
          value: "createdDateUTC",
          visible: false,
          showInDefault: false,
        },
        {
          text: "Account Code",
          value: "accountCode",
          visible: false,
          showInDefault: false,
        },
        {
          text: "Account Type",
          value: "accountType",
          visible: false,
          showInDefault: false,
        },
        {
          text: "Gross Amount",
          value: "grossAmount",
          visible: false,
          showInDefault: false,
        },
        {
          text: "Tax Amount",
          value: "taxAmount",
          visible: false,
          showInDefault: false,
        },
        {
          text: "Tracking Name",
          value: "name",
          visible: false,
          showInDefault: false,
        },
        // { text: "trackingOption", value: "option", visible: false },
        // { text: "trackingOptions", value: "options", visible: false },
      ],
      selectedColumns: [
        "journalDate",
        "journalNumberList",
        // "journalNumber",
        "reference",
        "sourceType",
        "accountName",
        "description",
        "credit",
        "debit",
      ],
      creditIndex: null,
      debitIndex: null,
    };
  },
  mounted() {
    this.fetchJournalsFromDB();
    this.currency = this?.currentWorkspace?.organisationDetails?.baseCurrency;
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
      user: "auth/getUser",
      paginate: "ticket/getPaginate",
      pageItems: "ticket/getPageItems",
    }),
    headerOptions() {
      this.columnsToAdd = this.selectedColumns;
      let data = this.headers.map((header) => ({
        text: header.text,
        value: header.value,
        showInDefault: header.showInDefault,
      }));
      data.splice(1, 1);
      return data;
    },
    visibleHeaders() {
      const data = this.headers.filter((header) =>
        this.selectedColumns.includes(header.value)
      );
      let crd;
      (this.creditIndex = null), (this.debitIndex = null);
      crd = data.findIndex((item) => item.text === "Credit");
      if (crd !== -1) this.creditIndex = crd;
      crd = data.findIndex((item) => item.text === "Debit");
      if (crd !== -1) this.debitIndex = crd;
      console.log("DDAAAAta", data);
      return data;
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    flattenedJournals() {
      return this.journals.flatMap((journal) => {
        return journal.journalLines.map((line) => {
          const flattenedLine = {
            ...journal,
            ...line,
            credit: line.netAmount < 0 ? -line.netAmount : 0,
            debit: line.netAmount >= 0 ? line.netAmount : 0,
          };

          line.trackingCategories.forEach((tc) => {
            flattenedLine[`${tc.name}name`] = tc.name;
            flattenedLine[`${tc.name}Option`] = tc.option;
          });

          return flattenedLine;
        });
      });
    },
  },
  methods: {
    addColumns() {
      this.selectedColumns = this.columnsToAdd;
      this.menu = false;
    },
    resetCheckbox() {
      this.selectedColumns = [
        "journalDate",
        "journalNumberList",
        // "journalNumber",
        "reference",
        "sourceType",
        "accountName",
        "description",
        "credit",
        "debit",
      ];
      this.menu = false;
    },
    sum(items, credit = true) {
      return items
        .reduce((total, item) => total + (credit ? item.credit : item.debit), 0)
        .toFixed(2);
    },

    setCustomDate() {
      this.fetchJournalsFromDB();
      this.dates = [];
      this.modal = false;
    },

    /**
     * async method which will pass the selected value to the date range case
     * and will wait until the start and end dates will be returned
     */
    async setDateValue() {
      let value = this.dateFilter.value;
      console.log(this.dateFilter, "Filter");

      let dates = await dateFilter.setDateFilter(value);
      console.log(dates, "dates");
      if (this.dateFilter.value !== "customDateRange") {
        this.dates[0] = dates.startDate.toISOString().substr(0, 10);
        this.dates[1] = dates.endDate.toISOString().substr(0, 10);
      } else if (this.dateFilter.value == "thisMonth") {
        this.dates[0] = new Date(dates.startDate + "Z")
          .toISOString()
          .substring(0, 10);
        this.dates[1] = dates.endDate.toISOString().substr(0, 10);
      } else if (
        this.dateFilter.value == "previousQuarter" ||
        this.dateFilter.value == "previousYear"
      ) {
        this.dates[0] = dates.startDate.toISOString().substring(0, 10);
        this.dates[1] = new Date(dates.endDate + "Z")
          .toISOString()
          .substring(0, 10);
      } else {
        this.modal = true;
      }

      // console.log(this.dates, "DATES FILTER");
      this.fetchJournalsFromDB();
    },

    // Method to fetch chart of accounts from database
    fetchJournalsFromDB(pgNo) {
      console.log(this.dates, "Date");
      if (pgNo === undefined || pgNo === null) pgNo = 1;
      let startDate = this.dates[0] ? this.dates[0] : "";
      let endDate = this.dates[1] ? this.dates[1] : "";
      this.loader = true;
      ApiService.GetRequest(
        `report/journals/${this.currentWorkspace.workspaceId}?startDate=${startDate}&endDate=${endDate}`,
        {
          page: pgNo,
          size: this.pageItems,
        }
      )
        .then((res) => {
          this.loader = false;
          this.$store.commit("ticket/setPaginate", {
            itemsPerPage: this.pageItems,
            page: pgNo,
            lastPage: res.data.count < 10 ? 1 : Math.ceil(res.data.count / 10),
            total: res.data.count,
          });
          this.journals = res.data.journals;
          this.menu = false;
        })
        .catch((err) => {
          console.log(err.response);
          this.loader = false;
        });
    },
  },
};
</script>

<style scoped>
:deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 12px !important;
  font-weight: 400 !important;
  color: var(--v-totalBlack-base) !important;
}
:deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: var(--v-totalBlack-base) !important;
}
.table-border {
  border-left: 1px solid var(--v-lightBackground-base);
  border-right: 1px solid var(--v-lightBackground-base);
  border-bottom: 1px solid var(--v-lightBackground-base);
  border-radius: 0 !important;
}
.settings-btn:hover {
  color: white !important;
}

.custom-btn {
  background-color: rgba(53, 16, 167, 0.05) !important;
  border-radius: 8px;
  min-height: 35px;
  line-height: 19px;
  color: var(--v-lightPurple-base) !important;
  padding: 10px 20px 10px 20px;
  box-shadow: none;
  margin-left: 10px;
}
.settings {
  width: 342px;
  height: 100%;
  background-color: white;
}
.bottom-tr-td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  /* margin-bottom: 15px; */
}
:deep .settings-checkbox label {
  font-size: 12px !important;
  color: rgba(0, 0, 0, 1) !important;
}
</style>
